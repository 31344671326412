import React from 'react';
import { Link } from 'react-router-dom';
import VideoItem from '../types/video';

interface VideoTileProps {
    IsFirst?: boolean;
    Template: "latest" | "work" | "production";
    Video: VideoItem;
};

const VideoTile = ({
    IsFirst = false,
    Template,
    Video
}: VideoTileProps) => {
    const videoId = Video.uri.split("/videos/");

    if (Template == "latest") {
        return(
            <Link
                className={"videolist-item videolist-item-latest" + (IsFirst ? " videolist-item-first" : "")}
                to={`/?video=${videoId[1]}`}
            >
                <img className="videolist-item-image" src={Video.pictures.base_link} alt={Video.name} loading="lazy" />
                <p className={"font-light videolist-item-title" + (IsFirst ? " videolist-item-title-first" : "")}>{Video.name}</p>
                <p className={"font-bold videolist-item-creator" + (IsFirst ? " videolist-item-creator-first" : "")}>{Video.description}</p>
            </Link>
        );
    };

    if (Template == "production") {
        return(
            <Link
                className={"videolist-item videolist-item-production"}
                to={`/?video=${videoId[1]}`}
            >
                <p className={"font-light videolist-item-title" + (IsFirst ? " videolist-item-title-first" : "")}>{Video.name}</p>
                <p className={"font-bold videolist-item-creator" + (IsFirst ? " videolist-item-creator-first" : "")}>{Video.description}</p>
                <img className="videolist-item-image videolist-item-image-production" src={Video.pictures.base_link} alt={Video.name} loading="lazy" />
            </Link> 
        );
    };

    return (
        <Link
            className="videolist-item"
            to={`/?video=${videoId[1]}`}
        >
            <img className="videolist-item-image" src={Video.pictures.base_link} alt={Video.name} loading="lazy" />
            <p className={"font-light videolist-item-title" + (IsFirst ? " videolist-item-title-first" : "")}>{Video.name}</p>
            <p className={"font-bold videolist-item-creator" + (IsFirst ? " videolist-item-creator-first" : "")}>{Video.description}</p>
        </Link>
    );
};

export default VideoTile;
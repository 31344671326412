import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Director from '../types/director';
import Spinner from '../Components/Spinner';

const Directors = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [directors, setDirectors] = useState<Director[]>(JSON.parse("[]"));

    const searchDirectors = async () => {
        setIsLoading(true);
        try {
            await axios.get('content/directors.json')
            .then(res => setDirectors(res.data))
            .catch(err => console.log(err));
        } catch (error) {
            console.log("Axios error");
            if (!axios.isCancel(error)) {
                // TODO error handling & error message
            }
        }
        setIsLoading(false);
    };

    useEffect(() => {
        searchDirectors();
    }, []);

    return (
        <section className="section">
            <h2 className="subtitle font-bold"><span className="subtitle-text">Directors</span></h2>
            {isLoading && <Spinner /> }
            {!isLoading && directors.length > 0 && (
                <div className="directors-container">
                    <div className="people people-directors">
                        {directors.map((item: Director, index: number) => (
                            <div className="people-item director-item" key={index}>
                                <h3 className="people-item-name font-light">{item.Name}</h3>
                                <p className="people-item-title font-bold">
                                    <a href={item.Url} target="_blank">{item.Site}</a>
                                </p>
                            </div>
                        ))}
                    </div>                      
                </div>              
            )}
        </section>
    );
};

export default Directors;

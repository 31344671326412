import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Producer from '../types/producer';
import Spinner from '../Components/Spinner';

const People = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [people, setPeople] = useState<Producer[]>(JSON.parse("[]"));

    const searchPeople = async () => {
        setIsLoading(true);
        try {
            await axios.get('content/producers.json')
            .then(res => setPeople(res.data))
            .catch(err => console.log(err));
        } catch (error) {
            console.log("Axios error");
            if (!axios.isCancel(error)) {
                // TODO error handling & error message
            }
        }
        setIsLoading(false);
    };

    useEffect(() => {
        searchPeople();
    }, []);

    return (
        <section className="section">
            <h2 className="subtitle font-bold"><span className="subtitle-text">People</span></h2>
            {isLoading && <Spinner /> }
            {!isLoading && people.length > 0 && (
                <div className="people">
                    {people.map((item: Producer, index: number) => (
                        <div className="people-item" key={index}>
                            <h3 className="people-item-name font-light">{item.Name}</h3>
                            <p className="people-item-title font-bold">{item.Title}</p>
                            <p className="people-item-contact font-light">{item.Phone}</p>
                            <p className="people-item-contact font-light">{item.Email}</p>
                        </div>
                    ))}
                </div>
            )}
        </section>
    );
};

export default People;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Content from '../types/content';
import Spinner from '../Components/Spinner';

const People = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [content, setContent] = useState<Content>(JSON.parse("{}"));

    const searchContent = async () => {
        setIsLoading(true);
        try {
            await axios.get('content/production.json')
            .then(res => setContent(res.data))
            .catch(err => console.log(err));
        } catch (error) {
            console.log("Axios error");
            if (!axios.isCancel(error)) {
                // TODO error handling & error message
            }
        }
        setIsLoading(false);
    };

    function createMarkup(String: string) {
        return {__html: String};
    };

    useEffect(() => {
        searchContent();
    }, []);

    return (
        <>
            {isLoading && <Spinner /> }
            {!isLoading && content.Content && <div className="production-content" dangerouslySetInnerHTML={createMarkup(content.Content)} />}
        </>
    );
};

export default People;
